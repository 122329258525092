function startPriceImport(priceImportObjectId) {
    Ext.Ajax.request({
        url: '/price-import/start',
        method: "POST",
        params: {
            id: priceImportObjectId
        },
        success: function (response) {
            alert('Import gestarted')
        },
        failure: function (response, options) {
            alert(response.responseText)
        }
    });
}